import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { format } from 'timeago.js';
import { Link } from 'gatsby';

import {
    Arrow,
    RichTextToHtml,
    ContentfulImage,
    NewsWidgetLayout,
    PracticeAreaBadge,
} from '../../components';
import useNews from '../../hooks/news';
import useViewport from '../../hooks/viewport';
import useTranslations from '../../hooks/translations';
import { navigationMapper } from '../../data';
import Controls from './controls';
import RelatedNews from './related-news';

import styles from './News.module.css';

export const News = ({ pageContext, location }) => {
    const {
        title,
        date,
        text,
        image,
        shortDescription,
        nextNewsLink,
        prevNewsLink,
        allExistingCategories,
        category,
    } = pageContext;

    const { allNews } = useNews();
    const { isMobile } = useViewport();
    const translations = useTranslations();

    const showControls = allNews?.length > 1;

    const relatedNews = useMemo(() => allNews?.filter((news) => (
        news.category.identifier === category.identifier && news.title !== title
    )), [allNews, category.identifier, title]);
    const hasRelatedNews = !!relatedNews?.length;

    const layoutData = {
        location,
        seoData: {
            title: `${title} - PA Advogados`,
            description: shortDescription,
            image: `https:${image.url}`,
        },
    };

    const nextNewsButton = {
        text: translations.next,
        link: nextNewsLink,
    };

    const prevNewsButton = {
        text: translations.previous,
        link: prevNewsLink,
    };

    return (
        <NewsWidgetLayout
            layout={ layoutData }
            className={ styles.widgetLayout }
            allCategories={ allExistingCategories }>
            { isMobile && (
                <>
                    <ContentfulImage
                        alt={ image.alt }
                        src={ image.url }
                        draggable={ false }
                        className={ styles.mobileImage } />
                    <Link to={ navigationMapper.news } className={ styles.backButton }>
                        <Arrow direction="left" className={ styles.arrow } />
                    </Link>
                </>
            )}
            <article className={ styles.newsContainer }>
                <p className={ styles.newsTitle }>{ translations.latestNews }</p>
                <ContentfulImage
                    alt={ image.alt }
                    src={ image.url }
                    className={ styles.image } />
                <p className={ styles.mobileCategory }>
                    { category.name }
                </p>
                <h1 className={ styles.title }>{ title }</h1>
                <div className={ styles.info }>
                    <PracticeAreaBadge
                        name={ category.name }
                        className={ styles.category } />
                    <p className={ styles.date }>{ format(new Date(date), 'pt_BR') }</p>
                </div>
                <div className={ styles.textContainer }>
                    <RichTextToHtml>{ text }</RichTextToHtml>
                </div>
                { showControls && <Controls
                    nextNews={ nextNewsButton }
                    prevNews={ prevNewsButton }
                    className={ styles.controls } /> }
            </article>
            { isMobile && hasRelatedNews && (
                <RelatedNews
                    news={ relatedNews }
                    className={ styles.relatedNews }
                    title={ `${translations.moreOf} ${category.name}:` } />
            ) }
        </NewsWidgetLayout>
    );
};

News.propTypes = {
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired, // Injected on page creation (gatsby-node.js)
};

export default News;
