import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { NewsGrid } from '../../../components';

import styles from './RelatedNews.module.css';

export const RelatedNews = ({ className, title, news }) => {
    const containerClasses = classNames(styles.container, className);

    return (
        <div className={ containerClasses }>
            <p className={ styles.title }>{ title }</p>
            <NewsGrid
                news={ news }
                isFirstHighlighted={ false } />
        </div>
    );
};

RelatedNews.propTypes = {
    className: PropTypes.string,
    news: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};

export default RelatedNews;
