import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

import styles from './Controls.module.css';

const ORIENTATION = {
    PREVIOUS: 'previous',
    NEXT: 'next',
};

export const ControlButton = ({
    to,
    children,
    className,
    orientation,
}) => {
    const classes = classNames(
        styles.control,
        styles[orientation],
        className,
    );

    return (
        <Link
            to={ to }
            className={ classes }>
            { children }
        </Link>
    );
};

ControlButton.propTypes = {
    className: PropTypes.string,
    to: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired,
    orientation: PropTypes.oneOf(Object.values(ORIENTATION)),
};

ControlButton.defaultProps = {
    orientation: ORIENTATION.PREVIOUS,
};

export const Controls = ({
    className,
    nextNews,
    prevNews,
}) => {
    const controlsContainerClasses = classNames(styles.controlsContainer, className);

    return (
        <div className={ controlsContainerClasses }>
            <ControlButton to={ prevNews.link }>{ prevNews.text }</ControlButton>
            <ControlButton
                to={ nextNews.link }
                orientation={ ORIENTATION.NEXT }>
                { nextNews.text }
            </ControlButton>
        </div>
    );
};

Controls.propTypes = {
    className: PropTypes.string,
    nextNews: PropTypes.shape({
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }).isRequired,
    prevNews: PropTypes.shape({
        text: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }).isRequired,
};

export default Controls;
